@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700;800&display=swap);
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Proxima Nova Bold";
    src: url(./assets/fonts/proxima-nova/proxima-nova-bold.otf) format("opentype")
}

@font-face {
    font-family: "Proxima Nova Regular";
    src: url(./assets/fonts/proxima-nova/proxima-nova-regular.otf) format("opentype")
}

@font-face {
    font-family: "Proxima Nova Semibold Italic";
    src: url(./assets/fonts/proxima-nova/Proxima-Nova-ScOsf-semibold-Italic.otf) format("opentype")
}

@font-face {
    font-family: "Proxima Nova Thin Italic";
    src: url(./assets/fonts/proxima-nova/proxima-nova-thin-Italic.otf) format("opentype")
}

/*Typography components*/
:root {
    --font-proxima-nova-bold: "Proxima Nova Bold", sans-serif;
    --font-proxima-nova-regular: "Proxima Nova Regular", sans-serif;
    --font-proxima-nova-semibold-italic: "Proxima Nova Semibold Italic", sans-serif;
}

@media screen and (max-width: 768px) {
    .py-xl {
        padding: 30rem 0;
    }
}

@layer components {
    .font-proxima-nova-bold {
        font-family: "Proxima Nova Bold", sans-serif !important;
    }

    .font-proxima-nova-regular {
        font-family: "Proxima Nova Regular", sans-serif !important;
    }

    .font-proxima-nova-semibold-italic {
        font-family: "Proxima Nova Semibold Italic", sans-serif !important;
    }

    .font-proxima-nova-thin-italic {
        font-family: "Proxima Nova Thin Italic", sans-serif !important;
    }
}

/*Util components*/
@layer components {
    .btn {
        @apply text-white uppercase p-4 rounded;
    }

    .btn.btn-red {
        @apply bg-primary-red;
    }
}

/*Body*/
body {
    @apply bg-blue-1a1a73 text-white;
}

/*Header components */
@layer components {
    .header-flex {
        @apply flex flex-col-reverse sm:flex-row gap-4 items-center justify-between;
    }
}

/* Hero Section */
.hero-section-bg {
    @apply bg-no-repeat bg-cover pb-10;
}

/*Hero section content  */
.hero-section-content-container {
    @apply container text-center mb-10;
    font-family: var(--font-proxima-nova-bold);
}

@media screen and (min-width: 1024px) {
    .hero-section-content-container {
        margin-top: 28.5rem;
    }
}

.hero-section-content-container .heading {
    @apply uppercase text-center mb-6;
}

.hero-section-content-container .heading {
    @apply flex items-center flex-col whitespace-pre-line;
}

.hero-section-content-container .heading span:nth-child(1) {
    @apply sm:text-8xl text-7xl;
}

.hero-section-content-container .heading span:nth-child(2) {
    @apply sm:text-2xl text-xl;
}

@media screen and (min-width: 1024px) {
    .hero-section-content-container .heading span:nth-child(2) {
        font-size: 3.4rem;
        line-height: 1;
    }
}


.hero-section-content-container .text {
    @apply text-white lg:text-3xl text-xl sm:leading-10 leading-8 px-4 sm:text-center text-left;
    font-family: var(--font-proxima-nova-regular);
}

/*.hero-section-banner {*/
/*    @apply bg-primary-red text-white flex items-center justify-center gap-2 p-4 my-6 uppercase;*/
/*}*/

/*.hero-section-banner h3 {*/
/*    @apply font-sans font-bold sm:text-4xl text-2xl;*/
/*}*/

/*Hero video section*/

/*hero video section text */
.hero-video-section-text-container {
    @apply container flex lg:flex-row flex-col items-start my-4 sm:py-20 py-12;
}

.resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    @apply mb-10;
}

.resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.text-bolder {
    @apply text-right font-sans uppercase flex flex-col items-start justify-start mb-5 lg:mb-0 px-4;
}

.text-bolder p:nth-child(1) {
    @apply sm:text-4xl text-2xl whitespace-nowrap;
    /*font-size: 49px;*/
    line-height: 62px;
}

.text-bolder p:nth-child(2) {
    @apply sm:text-8xl text-6xl whitespace-nowrap;
    @apply text-primary-red;
}

.text-bolder p:nth-child(3) {
    line-height: 95px;
    @apply bg-white text-black text-center sm:text-6xl text-3xl max-w-fit px-14;
    /*padding: .1rem 4.2rem;*/
}

@media screen and (min-width: 1280px) {
    .text-bolder p:nth-child(3) {
        font-size: 3.6rem;
        max-width: max-content;
        padding: 0 3.2rem;
    }
}

/* Tabs Section  */
.tabs-inner-container {
    @apply px-5 pt-5 pb-10 xl:rounded-tl-3xl xl:rounded-tr-3xl rounded-none;
    background: hsla(271, 100%, 50%, 1);
    background: linear-gradient(225deg, hsla(271, 100%, 50%, 1) 65%, hsla(345, 96%, 53%, 1) 100%);
    background: -moz-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 65%, hsla(345, 96%, 53%, 1) 100%);
    background: -webkit-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 65%, hsla(345, 96%, 53%, 1) 100%);
}

.tab-card {
    @apply bg-white p-5 rounded-lg;
}

.tab-btn {
    @apply px-14 font-proxima-nova-bold uppercase w-full text-sm sm:text-base;
    background-position: top center !important;
}


.tab-btn.tab-inactive-btn {
    color: #bfbebe;
    background: url('/src/assets/images/tab-button-bg.svg') no-repeat center center;
}

@media screen and (min-width: 1024px) {
    .tab-btn {
        height: 75px;
    }

    .tab-btn.tab-inactive-btn, .tab-btn.tab-active-btn {
        background-size: 375px 75px !important;
    }
}

@media screen and (max-width: 767px) {
    .tab-btn {
        height: 63px !important;
    }

    .tab-btn.tab-inactive-btn, .tab-btn.tab-active-btn {
        background-size: 250px 63px !important;
    }
}


.tab-btn.tab-active-btn {
    @apply text-white;
    background: url('/src/assets/images/tab-active-btn.svg') no-repeat center center;
}

.divider {
    @apply relative flex items-center text-center;
}

.divider img, .divider button {
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
}


/* To show the lines on right
and left sides of the text */
.divider::after,
.divider::before {
    @apply border-2 border-blue-1a1a73;
    content: "";
    flex: 1;
}

.divider-border-yellow-c1f84f::after,
.divider-border-yellow-c1f84f::before {
    @apply border-2 border-yellow-c1f84f;
    content: "";
    flex: 1;
}

.live-stream-text-border {
    @apply border-l-4 border-r-4 border-blue-1a1a73;
}

/* Space on left and right sides of text */
.divider.sm-divider-margin:not(:empty)::before {
    @apply lg:mr-20 sm:mr-10 mr-0;
}

.divider.sm-divider-margin:not(:empty)::after {
    @apply lg:ml-20 sm:ml-10 mr-0;
}

.divider.lg-divider-margin:not(:empty)::before {
    @apply sm:mr-72 mr-32;
}

.divider.lg-divider-margin:not(:empty)::after {
    @apply sm:ml-72 ml-32;
}

.Collapsible {
    @apply relative;
}

.Collapsible, .Collapsible__trigger {
    @apply text-white bg-blue-11114a cursor-pointer;
}

.Collapsible .Collapsible__trigger {
    @apply p-8 mb-0 rounded my-4  relative;
}

.Collapsible .Collapsible__trigger::after {
    content: url(/src/assets/icons/chevron-down.svg);
    width: 10px;
    @apply right-0 absolute px-8;
}

.Collapsible .Collapsible__trigger.is-open::after {
    content: url(/src/assets/icons/chevron-up.svg);
    width: 10px;
    @apply right-0 absolute px-8;
}

.Collapsible__trigger {
    @apply w-full block;
}

/*Modal Inputs */
@layer components {
    .form-group {
        @apply font-proxima-nova-regular text-black bg-white rounded-lg border-2 border-gray-e6e6e6 p-3 text-black;
    }
}
.border-2.border-red-600 {
    border: 2px solid red !important;
}

.error-input {
    @apply text-primary-red font-proxima-nova-regular capitalize text-xs absolute right-0;
}


/*CUSTOMIZE SLIDER*/
.slick-track {
    @apply flex items-center sm:max-w-screen-xl max-w-full;
}

.close-popup-btn {
    @apply absolute top-[20px] right-[20px] py-0 left-auto lg:top-[74px] lg:left-[-74px];
    background: none;
}

.close-popup-btn::after {
    content: 'X';
    @apply font-sans z-50;
}

/*close popup*/
@media screen and (min-width: 1024px) {
    .close-popup-btn {
        background: url("/public/ashnoo/images/btn-close.svg") no-repeat center center/contain;
        width: 77px;
        height: 194px;
    }

    .close-popup-btn::after {
        content: '';
    }
}
